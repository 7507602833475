<script>
import NotificationsList from '@/infrastructure/notifications/components/NotificationsList'
import useRealtimeSubscriptions from '@/infrastructure/realtime/realtimeSubscriptions'
import store from '@/infrastructure/store'

export default {
  components: {
    NotificationsList,
  },

  setup() {
    const { subscribeToRealtimeEvent } = useRealtimeSubscriptions()
    subscribeToRealtimeEvent('Notification:Created', () => store.dispatch('notifications/fetchUnreadNotifications'))
  },

  data() {
    return {
      isLoadingUnread: false,
      isMenuShowing: false,
    }
  },

  computed: {
    unreadNotificationCount: () => store.state.notifications.unreadNotificationCount,
    unreadNotifications: () => store.getters['notifications/unreadNotifications'],

    cardTitle() {
      return this.unreadNotificationCount === 1 ? `You have 1 unread notification` : `You have ${this.unreadNotificationCount} unread notifications`
    },
  },

  watch: {
    isMenuShowing(to) {
      if (to) {
        this.loadUnreadNotification()
      }
    },
    ['$route.path']() {
      this.isMenuShowing = false
    },
  },

  methods: {
    async loadUnreadNotification() {
      this.isLoadingUnread = true
      try {
        await Promise.all([
          //
          store.dispatch('notifications/fetchUnreadNotifications'),
          store.dispatch('notifications/fetchUnreadNotificationCount'),
        ])
      } finally {
        this.isLoadingUnread = false
      }
    },
  },
}
</script>

<template>
  <v-menu v-model="isMenuShowing" :close-on-content-click="false" :nudge-width="500" max-width="500" offset-x left bottom :nudge-bottom="50" :nudge-right="50">
    <template #activator="{ on, attrs }">
      <slot name="activator" v-bind="{ on, attrs }" />
    </template>

    <v-card :loading="isLoadingUnread">
      <v-card-title>
        <h4>{{ cardTitle }}</h4>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <div class="d-flex align-center">
          <div><strong>New</strong></div>
          <v-spacer />
          <router-link v-slot="{ href, navigate }" :to="{ name: 'notifications' }" custom>
            <v-btn :href="href" text color="primary" class="px-1" @click.stop="navigate"> See all</v-btn>
          </router-link>
        </div>

        <div class="pt-3"></div>

        <div class="pa-3" style="max-height: 75vh; overflow-y: auto">
          <NotificationsList :notifications="unreadNotifications" item-css-class="mt-3" />
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>
