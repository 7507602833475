
import { defineComponent } from '@vue/composition-api'
import { PropType } from '@vue/composition-api'

export default defineComponent({
  props: {
    action: { type: Function as PropType<() => Promise<unknown>>, required: true },
  },

  data() {
    return {
      isExecuting: false,
    }
  },

  computed: {
    buttonEnabled(): boolean {
      return !this.isExecuting
    },
  },

  methods: {
    async onClick() {
      this.isExecuting = true
      try {
        await this.action()
      } finally {
        this.isExecuting = false
      }
    },
  },
})
