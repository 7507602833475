import { Module } from 'vuex'

import { ErrandJobType, PriceUnit } from '@/features/errands/errands-shared.models'
import { createApiInstance } from '@/infrastructure/apis/apiInstance'
import { RootState } from '@/infrastructure/store/RootState'

export interface ActiveSkillAsAdmin {
  skillType: ErrandJobType
  price: {
    amount: number
    unit: PriceUnit
  }
}

export interface UserForAdmin {
  userId: string
  fullName: string
  email: string
  lastLogin: string
  picture: string
  roles: string[]
  carerUserId: string

  hasPayfastSellerInfo: boolean

  activeSkills: Array<ActiveSkillAsAdmin>
}

export interface VuexAdminState {
  users: UserForAdmin[]
}

const initialState: VuexAdminState = {
  users: [],
}

export const adminModule: Module<VuexAdminState, RootState> = {
  namespaced: true,

  state: () => initialState,

  getters: {},

  mutations: {
    setUsers(state, { users }) {
      state.users = users || []
    },
  },

  actions: {
    async fetchFakeError(context, { errorType }) {
      const response = await createApiInstance().get(`/Admin/fake-error?errorType=${errorType}`)
      return response.data
    },

    async fetchUsers(context) {
      const response = await createApiInstance().get(`/Admin/users`)
      context.commit('setUsers', { users: response.data })
    },
  },
}
