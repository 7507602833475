// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ref, watch } from '@vue/composition-api'

import dayjs from 'dayjs'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

export function formatDate(date: string, format: string) {
  return dayjs(date).format(format)
}

export function formatRelativeTimeAgo(date: string) {
  return dayjs(date).fromNow()
}

export function addMonthsToDate(date: string, numMonths: number) {
  return dayjs(date).add(numMonths, 'month').toISOString()
}

const relativeTimeAgosToUpdate: unknown[] = []

setInterval(() => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  relativeTimeAgosToUpdate.forEach((r: any) => {
    r.ref.value = formatRelativeTimeAgo(r.originalDateRef.value)
  })
}, 15 * 1000)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function reactiveFormatRelativeTimeAgo(dateRef: any) {
  const timeAgo = ref(formatRelativeTimeAgo(dateRef.value))

  const futureContainer = {
    originalDateRef: dateRef,
    ref: timeAgo,
  }

  watch(dateRef, (newValue: string) => {
    futureContainer.ref.value = newValue ? formatRelativeTimeAgo(newValue) : ''
  })

  relativeTimeAgosToUpdate.push(futureContainer)

  return timeAgo
}
