
import { defineComponent } from '@vue/composition-api'

import config from '@/config'

import { RecentLogin } from '@/infrastructure/auth/types'

export default defineComponent({
  data() {
    return {
      appName: config.AppName,
      pageLayoutProps: config.PageLayouts.default,
      homeImage: `https://imaginary-z3et7l22ma-ew.a.run.app/thumbnail?url=https%3A%2F%2Fstorage.googleapis.com%2Fimaginary-public-images%2Fscoutassist%2Flogos%2Fbanner-1.png&nocrop=true&width=1000&height=400`,
      homeImageWidth: 1000,
    }
  },

  computed: {
    recentLogins(): RecentLogin[] {
      return this.$store.state.auth.recentLogins || []
    },

    loginCards() {
      const buttons = []

      for (let i = 0; i < this.recentLogins.length; i++) {
        const recentLogin = this.recentLogins[i] as RecentLogin
        const connection = config.Auth0.Connections.find((con) => con.name === recentLogin.connectionName)
        buttons.push({
          email: recentLogin.email,
          image: recentLogin.picture,
          connection: connection,
          onClick: () =>
            this.$store.commit('auth/authRedirectToConnection', {
              connectionName: connection ? connection.name : undefined,
              email: recentLogin.email,
            }),
        })
      }

      return buttons
    },

    loginButtons() {
      return config.Auth0.Connections.map((con) => ({
        text: `Sign in with ${con.displayName}`,
        icon: con.icon,
        cssClass: con.cssClass,
        onClick: () => this.$store.commit('auth/authRedirectToConnection', { connectionName: con.name }),
      }))
    },
  },
})
