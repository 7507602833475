import { computed, ref, watch } from '@vue/composition-api'

export default function debounce(fn: (args: string) => void, delay: number) {
  let timeoutID: number | undefined = undefined
  return function (...args: unknown[]) {
    clearTimeout(timeoutID)
    timeoutID = setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      fn.apply(this, args)
    }, delay)
  }
}

type useDebouncedAutoSavedInputFieldRefArgs = { initialValue: unknown; saveFunc: (newVal: unknown) => void; delay?: number }

export function useDebouncedAutoSavedInputFieldRef({ initialValue, saveFunc, delay }: useDebouncedAutoSavedInputFieldRefArgs) {
  if (!saveFunc) throw new Error('saveFunc is required in useDebouncedAutoSavedInputFieldRef')

  const defaultDelay = 500

  const val = ref(initialValue)

  const isSavingCount = ref(0)
  const doneCount = ref(0)

  watch(
    val,
    debounce(async (newVal) => {
      isSavingCount.value++
      try {
        await saveFunc(newVal)
        doneCount.value++
        setTimeout(() => {
          doneCount.value--
        }, 3000)
      } finally {
        isSavingCount.value--
      }
    }, delay || defaultDelay),
  )

  const isSaving = computed(() => isSavingCount.value > 0)
  const isShortlyAfterSaved = computed(() => doneCount.value > 0)

  return {
    val,
    isSaving,
    isShortlyAfterSaved,
  }
}
