import Vue from 'vue'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as VueGoogleMaps from 'vue2-google-maps'

import config from '@/config'

Vue.use(VueGoogleMaps, {
  load: {
    key: config.GoogleMaps.ApiKey,
  },
})
