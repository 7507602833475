
import { defineComponent } from '@vue/composition-api'

import GlobalErrorSnackbar from './GlobalErrorSnackbar.vue'

import store from '@/infrastructure/store'

export default defineComponent({
  components: {
    GlobalErrorSnackbar,
  },

  computed: {
    errors: () => store.state.errors,
  },

  methods: {
    removeError(error: unknown) {
      store.commit('removeError', error)
    },
  },
})
