import Vue from 'vue'

import { Module } from 'vuex'

import { LogoutOptions, RedirectLoginOptions, User } from '@auth0/auth0-spa-js'

import { RecentLogin } from '@/infrastructure/auth/types'
import { RootState } from '@/infrastructure/store/RootState'

interface Auth0VueInstance extends Vue {
  loginWithRedirect: (o: RedirectLoginOptions) => void
  logout: (o: LogoutOptions | undefined) => void
}

const recentLoginsStateKey = 'recent-logins'
let auth0Instance: Auth0VueInstance | null = null

export interface VuexAuthState {
  authUser?: User
  authUserId?: string
  hasOfficialUserId: boolean
  authToken?: string
  authUserRoles: string[]
  authIsAuthenticated: boolean

  hasBusinessCard: boolean

  authBusyLoading: boolean
  authTakingLong: boolean
  authFullError?: string

  recentLogins: RecentLogin[]
}

const initialState: VuexAuthState = {
  authUser: undefined,
  authUserId: undefined,
  hasOfficialUserId: false,
  authUserRoles: [],
  authToken: undefined,
  authIsAuthenticated: false,

  hasBusinessCard: false,

  authBusyLoading: true,
  authTakingLong: false,
  authFullError: undefined,

  recentLogins: JSON.parse(localStorage.getItem(recentLoginsStateKey) || '[]') as RecentLogin[],
}

const authModule: Module<VuexAuthState, RootState> = {
  namespaced: true,

  state: () => initialState,

  getters: {
    authHasError: (state) => !!state.authFullError,
  },

  mutations: {
    setAuth0Instance(state, instance: Auth0VueInstance) {
      auth0Instance = instance
    },

    setAuthTakingLong(state) {
      if (state.authToken) console.error('setAuthTakingLong should actually not be called when authToken is set')

      state.authTakingLong = true
    },

    setAuthError(state, { fullError }: { fullError: string }) {
      console.error('setAuthError', { fullError })

      state.authFullError = fullError
    },
    clearAuthError(state) {
      state.authFullError = undefined
    },

    setAuthUserId(state, { authUserId, hasOfficialUserId }) {
      state.authUserId = authUserId
      state.hasOfficialUserId = hasOfficialUserId
    },

    setAuthUserHasBusinessCard(state, { hasBusinessCard }) {
      state.hasBusinessCard = hasBusinessCard
    },

    authRedirectToConnection(state, { connectionName, email }) {
      if (auth0Instance == null) {
        console.error('Cannot authRedirectToConnection when auth0Client is null')
        return
      }

      auth0Instance.loginWithRedirect({
        connection: connectionName,
        login_hint: email,
      })
    },

    authLogout() {
      if (auth0Instance == null) {
        console.error('Cannot authRedirectToConnection when auth0Client is null')
        return
      }

      auth0Instance.logout({
        returnTo: window.location.origin,
      })
    },

    setAuthUserAndToken(state, { user, token, roles, connectionName, firepumaUserId }) {
      state.authUser = user
      state.authUserId = firepumaUserId || (user ? user.sub : undefined)
      state.authUserRoles = roles || []
      state.authToken = token
      state.authIsAuthenticated = !!(user && token)
      state.authBusyLoading = false

      if (token) {
        state.authTakingLong = false
      }

      if (token && user && user.email) {
        const recentLogin = {
          email: user.email,
          picture: user.picture,
          connectionName: connectionName,
        }

        const foundIdx = state.recentLogins.findIndex((u) => u.email === user.email)

        if (foundIdx === -1) {
          state.recentLogins.push(recentLogin)
        } else {
          state.recentLogins[foundIdx] = recentLogin
        }

        localStorage.setItem(recentLoginsStateKey, JSON.stringify(state.recentLogins))
      }
    },
  },

  actions: {
    initializeAuth(context, instance: Auth0VueInstance) {
      context.commit('setAuth0Instance', instance)
      setTimeout(() => {
        if (!context.state.authToken) {
          context.commit('setAuthTakingLong')
        }
      }, 8000)
    },
  },
}

export default authModule
