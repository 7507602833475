// https://zellwk.com/blog/case-conversion/

export function toKebabCase(s: string) {
  return s.replace(/[_\s]+/g, '-')
}

export function toTitleCase(s: string) {
  return toKebabCase(s)
    .split('-')
    .map((word) => {
      return word.slice(0, 1).toUpperCase() + word.slice(1)
    })
    .join(' ')
}

export function toSentenceCase(s: string) {
  const interim = toKebabCase(s).replace(/-/g, ' ')
  return interim.slice(0, 1).toUpperCase() + interim.slice(1)
}

export function toCamelCase(s: string) {
  return toKebabCase(s)
    .split('-')
    .map((word, index) => {
      if (index === 0) return word
      return word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase()
    })
    .join('')
}
