import config from '@/config'

import { RouteDefinition } from '@/infrastructure/router/routeDefinition'

function autoFillRouteMetaTitleFromName(route: RouteDefinition) {
  if (!route.meta) route.meta = {}

  route.meta.title = route.name?.replace('admin-', '').replace(/-/g, ' ')

  return route
}

export default (
  [
    {
      path: 'feedback',
      name: 'admin-feedback',
      component: () => import(/* webpackChunkName: "admin" */ '@/infrastructure/userFeedbacks/views/UserFeedbackForAdminView.vue'),
    },

    {
      path: 'command-execution-events',
      name: 'admin-command-execution-events',
      component: () => import(/* webpackChunkName: "admin" */ '@/infrastructure/admin/components/CommandExecutionEvents.vue'),
      meta: {
        pageLayoutProps: config.PageLayouts.wideFluid,
      },
    },

    {
      path: 'users',
      name: 'admin-users',
      component: () => import(/* webpackChunkName: "admin" */ '@/infrastructure/admin/components/AdminUsersList.vue'),
    },
  ] as Array<RouteDefinition>
).map((route) => autoFillRouteMetaTitleFromName(route))
