import { Module } from 'vuex'

import { createApiInstance } from '@/infrastructure/apis/apiInstance'

import { RootState } from '@/infrastructure/store/RootState'

export interface VuexUserProfileState {
  pushNotificationSubscription?: { endpoint: string; unsubscribe: () => void }
}

const initialState: VuexUserProfileState = {
  pushNotificationSubscription: undefined,
}

export const userProfileModule: Module<VuexUserProfileState, RootState> = {
  namespaced: true,
  state: () => initialState,

  getters: {
    hasPushNotificationSubscription: (state) => !!state.pushNotificationSubscription,
  },

  mutations: {
    setPushNotificationSubscription(state, { subscription }) {
      state.pushNotificationSubscription = subscription
    },
  },

  actions: {
    async loadBirthDayAndMonth() {
      const response = await createApiInstance().get(`/UserProfile/birthday`)
      return response.data
    },
    async saveBirthDayAndMonth(context, { day, month }) {
      await createApiInstance().post(`/UserProfile/birthday?day=${day}&month=${month}`)
    },
  },
}
