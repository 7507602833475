import { onMounted, onUnmounted, ref } from '@vue/composition-api'

export function useOnlineDetection() {
  const isOnline = ref<boolean>(navigator.onLine)

  const setIsOnlineTrue = () => {
    isOnline.value = true
  }

  const setIsOnlineFalse = () => {
    isOnline.value = false
  }

  onMounted(() => {
    window.addEventListener('online', setIsOnlineTrue)
    window.addEventListener('offline', setIsOnlineFalse)
  })

  onUnmounted(() => {
    window.removeEventListener('online', setIsOnlineTrue)
    window.removeEventListener('offline', setIsOnlineFalse)
  })

  return {
    isOnline,
  }
}
