
import { defineComponent, ref, watch } from '@vue/composition-api'

import { useOnlineDetection } from '@/infrastructure/pwa/useOnlineDetection'

export default defineComponent({
  setup() {
    const { isOnline } = useOnlineDetection()
    const isAlertVisible = ref<boolean>(true)

    watch(isOnline, () => {
      // make alert visible again when isOnline changes
      isAlertVisible.value = true
    })

    return {
      isOnline,
      isAlertVisible,
    }
  },
})
