
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    message: { type: String, required: true },
  },

  data() {
    return {
      isOpen: true,
    }
  },

  watch: {
    isOpen(to) {
      if (!to) {
        this.$emit('close')
      }
    },
  },
})
