
import { defineComponent, ref } from '@vue/composition-api'

import { callFetchPaymentTransactionDetails } from '@/features/payments/payments.api'
import { TransactionDetails } from '@/features/payments/payments.models'
import useAsyncLoading from '@/infrastructure/apis/asyncLoadingComposable'

export default defineComponent({
  setup() {
    const transactionDetails = ref(undefined as TransactionDetails | undefined)

    const { isBusy: isLoading, execute: fetchTransactionDetails } = useAsyncLoading(async (transactionId: string) => {
      transactionDetails.value = await callFetchPaymentTransactionDetails({ transactionId: transactionId })
    })

    return {
      isLoading,
      fetchTransactionDetails,
      transactionDetails,
    }
  },

  computed: {
    action(): 'cancel' | 'return' | string | (string | null)[] {
      return this.$route.query?.action
    },

    transactionId(): string | (string | null)[] {
      return this.$route.query?.tx
    },
  },

  watch: {
    transactionId: {
      handler(to: string) {
        this.fetchTransactionDetails(to)
      },
      immediate: true,
    },
  },
})
