import { PageLayoutProps } from '@/infrastructure/router/routeDefinition'

const defaultUrlsMap: Record<string, { api: string; realtimeHub: string }> = {
  'https://scoutassist.co.za': {
    api: '/api',
    realtimeHub: 'https://scout-assist-webapi-z3et7l22ma-ew.a.run.app/api/realtime/unified',
  },

  'http://localhost:8080': {
    api: '/api',
    realtimeHub: 'https://localhost:56811/api/realtime/unified',
  },

  'http://localhost': {
    api: 'UNIT_TEST_PLACEHOLDER',
    realtimeHub: 'UNIT_TEST_PLACEHOLDER',
  },
}

function getUrls(): { apiUrl: string; realtimeHubUrl: string } {
  // const apiUrl = localStorage.getItem('api-url') || `${location.origin}/api` //'https://scoutassist.co.za/api'

  const defaultUrls = defaultUrlsMap[window.location.origin]

  const apiUrlOverride = localStorage.getItem('api-url')

  if (apiUrlOverride) {
    console.warn('Using custom api url override', apiUrlOverride)
  }

  const realtimeHubUrlOverride = localStorage.getItem('realtime-hub-url')
  if (realtimeHubUrlOverride) {
    console.warn('Using custom realtime hub url override', realtimeHubUrlOverride)
  }

  const apiUrl = apiUrlOverride || defaultUrls?.api
  const realtimeHubUrl = apiUrlOverride || defaultUrls?.realtimeHub

  if (!apiUrl) {
    const err = `Unable to determine apiUrl, no mapping found for origin '${window.location.origin}' and also no api-url in localStorage`
    alert(err)
    console.error(err)
    throw new Error(err)
  }

  if (!realtimeHubUrl) {
    const err = `Unable to determine realtimeHubUrl, no mapping found for origin '${window.location.origin}' and also no realtime-hub-url in localStorage`
    alert(err)
    console.error(err)
    throw new Error(err)
  }

  return { apiUrl, realtimeHubUrl }
}

const { apiUrl, realtimeHubUrl } = getUrls()
console.debug('using', { apiUrl, realtimeHubUrl })

export default {
  IsProductionEnvironment: process.env.NODE_ENV === 'production',

  Version: process.env.VUE_APP_VERSION,

  AppName: process.env.VUE_APP_DISPLAY_NAME,
  AppFrontendUrl: process.env.VUE_APP_WEBUI_BASE_URL,
  PrivacyPolicyContactEmail: 'francoishill11@gmail.com',
  ApiBaseUrl: apiUrl,
  RealtimeHubUrl: realtimeHubUrl,

  LogoUrl: `https://imaginary-z3et7l22ma-ew.a.run.app/thumbnail?url=https%3A%2F%2Fstorage.googleapis.com%2Fimaginary-public-images%2Fscoutassist%2Flogos%2Fbanner-1.png&nocrop=true&width=103&height=40`,

  AppInsightsInstrumentationKey: process.env.VUE_APP_APP_INSIGHTS_INSTRUMENTATION_KEY,
  AppInsightsEnabled: true,
  AppInsightsCloudRoleName: 'WebFrontend',

  ApiDefaultTimeoutSeconds: 60,

  Auth0: {
    Domain: 'firepuma.eu.auth0.com',
    ClientId: 'd8TEZ3984dYFBA8TWJELuwS0DsrYkYpq',
    Audience: 'https://scoutassist.firepuma.com',
    ClaimsNamespacePrefix: 'https://firepuma.com/',
    AuthorizeTimeoutInSeconds: 30,
    Connections: [
      { name: 'Username-Password-Authentication', displayName: 'Password', icon: 'mdi-email', cssClass: '' },
      { name: 'google-oauth2', displayName: 'Google', icon: 'mdi-google', cssClass: 'indigo--text text--accent-2' },
      { name: 'facebook', displayName: 'Facebook', icon: 'mdi-facebook', cssClass: 'indigo--text' },
    ],
  },
  AdminRoleName: 'snaptask:Admin',
  UserRoleFilterPredicate: (role: string) => role.toLowerCase().indexOf('snaptask:') >= 0,

  Vapid: {
    PublicKey: process.env.VUE_APP_VAPID_PUBLIC_KEY,
  },

  GoogleMaps: {
    ApiKey: 'AIzaSyBqnvgqJJo-yKZQKvMg901ux242DSLGoi8',
  },
  FetchLocationTimeoutSeconds: 10,

  PageLayouts: {
    default: {
      contentContainerAttrs: { fluid: false },
      contentColumnAttrs: { cols: '12', xl: '6', lg: '7', md: '8', sm: '9' },
    },
    wideFluid: {
      contentContainerAttrs: { fluid: true },
      contentColumnAttrs: { cols: '12' },
    } as PageLayoutProps,
  } as Record<'default' | 'wideFluid', PageLayoutProps>,

  BusinessCardWidth: 380,
  CalendarColors: {
    JobPaidByPatron: 'info',
    JobDoneByWorker: 'green',
  },
}
