import { RouteDefinition } from '@/infrastructure/router/routeDefinition'

function autoFillRouteMetaTitleFromName(route: RouteDefinition) {
  if (!route.meta) route.meta = {}

  route.meta.title = route.name?.replace('playground-', '').replace(/-/g, ' ')

  return route
}

export default (
  [
    {
      path: 'jobs-as-patron',
      name: 'playground-jobs-as-patron',
      component: () => import(/* webpackChunkName: "playground" */ '@/playground/components/PlayJobAsPatron.vue'),
    },
  ] as Array<RouteDefinition>
).map((route) => autoFillRouteMetaTitleFromName(route))
