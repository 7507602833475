<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    message: { type: String, required: true },
    color: { type: String, default: () => '' },
    timeout: { type: Number, required: true },
    actionText: { type: String, default: () => '' },
    actionFunction: { type: Function, default: () => null },
  },

  data() {
    return {
      isOpen: true,
      isActionBusy: false,
    }
  },

  computed: {
    htmlMessage() {
      return this.message.replace(/\n/g, '<br>')
    },
  },

  watch: {
    isOpen(to) {
      if (!to) {
        this.$emit('close')
      }
    },
  },

  methods: {
    async onAction() {
      if (!this.actionFunction) {
        this.isOpen = false
        return
      }

      this.isActionBusy = true
      try {
        await this.actionFunction()
      } finally {
        this.isActionBusy = false
        this.isOpen = false
      }
    },
  },
})
</script>

<template>
  <v-snackbar v-model="isOpen" app :timeout="timeout" :color="color">
    <div v-text="htmlMessage"></div>

    <template #action="{ attrs }">
      <v-btn text v-bind="attrs" :disabled="isActionBusy" :loading="isActionBusy" @click="onAction">
        {{ actionText || 'Close' }}
      </v-btn>
    </template>
  </v-snackbar>
</template>
