
import { Vue } from 'vue-property-decorator'

import ChooseJobType from '@/features/errands/patron/components/ChooseJobType.vue'

export default Vue.extend({
  components: {
    ChooseJobType,
  },
})
