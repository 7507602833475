<script>
import { defineComponent } from '@vue/composition-api'

import GlobalToastSnackbar from './GlobalToastSnackbar'

import { useToastMessages } from '@/infrastructure/toasts/useToastMessages'

export default defineComponent({
  components: {
    GlobalToastSnackbar,
  },

  setup() {
    const { toasts, removeToast } = useToastMessages()

    return {
      toasts,
      removeToast,
    }
  },
})
</script>

<template>
  <div>
    <GlobalToastSnackbar
      v-for="toast in toasts"
      :key="toast.id"
      :message="toast.message"
      :color="toast.color"
      :timeout="toast.timeout"
      :action-text="toast.actionText"
      :action-function="toast.actionFunction"
      @close="removeToast(toast)"
    />
  </div>
</template>
