import { RawLocation } from 'vue-router'

export enum NotificationTypes {
  UserFeedbackAdded = 'UserFeedbackAdded',
  UserFeedbackReplyFromAdmin = 'UserFeedbackReplyFromAdmin',
  RequestQuoteFromWorker = 'RequestQuoteFromWorker',
  JobQuoteDeadlineDone = 'JobQuoteDeadlineDone',
  QuoteCreatedByWorker = 'QuoteCreatedByWorker',
  JobRejectedByWorker = 'JobRejectedByWorker',
  JobAcceptedByPatron = 'JobAcceptedByPatron',
  JobRejectedByPatron = 'JobRejectedByPatron',
  JobPaymentConfirmed = 'JobPaymentConfirmed',
  JobChatMessageSent = 'JobChatMessageSent',
}

export interface CardAction {
  name: string
  attributes: unknown
  icon?: string
  routeTo?: RawLocation
  func?: () => void
}
