import { ErrandJobStatus, ErrandJobType, PriceUnit, PriceWithUnit } from '@/features/errands/errands-shared.models'

export interface ErrandSummary {
  key: ErrandJobType

  name: string
}

export interface ActiveSkillSummary {
  skillType: ErrandJobType
  price: PriceWithUnit
  averageRating: number
  totalRatingCount: number
}

export interface ErrandProviderUser {
  workerId: string
  displayName: string
  picture: string
  errandJobType: ErrandJobType
  averageRating: number
  totalRatingCount: number
  totalJobsDone: number
  price: PriceWithUnit
}

export interface WorkerReview {
  id: string
  createdOn: string
  errandJobId: string
  errandJobType: ErrandJobType
  reviewerPatronId: string
  revieweeWorkerId: string
  summary: string
  rating: number
  timestamp: string
}

export const enabledErrands: Array<ErrandJobType> = [
  ErrandJobType.ErrandRunning,
  ErrandJobType.Nanny,
  ErrandJobType.PetSitting,
  ErrandJobType.GraphicDesign,
  ErrandJobType.DigitalMarketing,
  ErrandJobType.Research,
  ErrandJobType.Domestic,
  ErrandJobType.Gardening,
]
export const enabledErrandShortlist = enabledErrands.slice(0, 3)

export const errandDescriptionsForPatron: Record<ErrandJobType, string> = {
  [ErrandJobType.Research]: 'Research a topic',
  [ErrandJobType.GraphicDesign]: 'Do graphic design',
  [ErrandJobType.DigitalMarketing]: 'Do digital marketing',
  [ErrandJobType.PetSitting]: 'Get someone to pet / house sit for you',
  [ErrandJobType.Domestic]: 'Domestic work around your home',
  [ErrandJobType.Gardening]: 'Gardening services',
  [ErrandJobType.ErrandRunning]: 'Run your errands',
  [ErrandJobType.Nanny]: 'Nanny for your kids',
}

export const jobStatusForPatronDescriptions: Record<ErrandJobStatus, string> = {
  WaitingForQuote: 'Waiting for quote',
  AcceptedByWorker: 'Accepted by worker',
  RejectedByWorker: 'Rejected by worker',
  AcceptedByPatron: 'Accepted by patron',
  RejectedByPatron: 'Rejected by patron',
  PaidByPatron: 'Paid by patron',
}

export interface PreparePayfastPaymentResponse {
  redirectUrl: string
}

export interface PriceAsPatron {
  amount: number
  unit: PriceUnit
}

export interface ErrandJobAsPatron {
  errandJobId: string
  patronId: string
  workerId: string
  description: string
  errandType: ErrandJobType
  workerPrice: PriceAsPatron
  estimatedQuantity: number
  estimatedTotalCost: number
  commissionAmount: number
  status: ErrandJobStatus
  quoteDeadlineIsPast: boolean
  createdOn: string
  startTime: string
  quoteCreatedByWorkerOn: string
  quoteAcceptedByPatronOn: string
  paidByPatronOn: string
}

export interface WorkerProfile {
  userId: string
  email: string
  fullName: string
  picture: string
  activeSkillSummaries: ActiveSkillSummary[]
}
