import {
  ChatListSummaryResponse,
  ErrandCalendarEntry,
  JobChatMessagesResponse,
  JobChatMessageViewModel,
  JobTimelineResponse,
} from '@/features/errands/errands-shared.models'
import { createApiInstance } from '@/infrastructure/apis/apiInstance'

export async function getErrandCalendarEntriesBetweenDates({ fromDate, toDate }: { fromDate: string; toDate: string }) {
  const response = await createApiInstance().get(`/ErrandsCalendar/entries-between-dates?fromDate=${fromDate}&toDate=${toDate}`)
  return response.data as ErrandCalendarEntry[]
}

export async function getChatListSummaries() {
  const response = await createApiInstance().get(`/JobChatList/summaries?limit=20`)
  return response.data as ChatListSummaryResponse[]
}

export async function getChatListSummary({ errandJobId }: { errandJobId: string }): Promise<ChatListSummaryResponse> {
  const response = await createApiInstance().get(`/JobChatList/summaries/${errandJobId}`)
  return response.data as ChatListSummaryResponse
}

export async function getTotalUnreadChatCount() {
  const response = await createApiInstance().get(`/JobChatList/total-unread-count`)
  return response.data as number
}

export async function callGetJobChatUnreadCount({ errandJobId }: { errandJobId: string }) {
  const response = await createApiInstance().get(`/JobChatMessages/${errandJobId}/count-unread`)
  return response.data as number
}

export async function callGetJobChatMessageList({ errandJobId, beforeTime, limit }: { errandJobId: string; beforeTime?: string; limit?: number }) {
  let queryParams = ``
  if (beforeTime !== undefined) queryParams += `&beforeTime=${encodeURIComponent(beforeTime)}`
  if (limit !== undefined) queryParams += `&limit=${limit}`

  const response = await createApiInstance().get(`/JobChatMessages/${errandJobId}?${queryParams}`)
  return response.data as JobChatMessagesResponse
}

export async function callGetJobTimeline({ errandJobId }: { errandJobId: string }) {
  const response = await createApiInstance().get(`/JobTimeline/${errandJobId}`)
  return response.data as JobTimelineResponse
}

export async function callGetJobChatMessage({ errandJobId, chatMessageId }: { errandJobId: string; chatMessageId: string }) {
  const response = await createApiInstance().get(`/JobChatMessages/${errandJobId}/messages/${chatMessageId}`)
  return response.data as JobChatMessageViewModel
}

export async function callSendJobChatMessage({ errandJobId, messageText }: { errandJobId: string; messageText: string }) {
  const data = {
    messageText: messageText,
  }
  const response = await createApiInstance().post(`/JobChatMessages/${errandJobId}`, data)
  return response.data as JobChatMessageViewModel
}

export async function callMarkJobMessagesReadUntil({ errandJobId, untilDate }: { errandJobId: string; untilDate: string }) {
  const response = await createApiInstance().put(`/JobChatMessages/${errandJobId}/messages/mark-read-until/${untilDate}`)
  return response.data
}
