import { computed, reactive } from '@vue/composition-api'

interface ToastMessageWithoutId {
  id?: number
  message: string
  color?: 'info' | 'success' | 'error' | 'warning'
  timeout?: number
  actionText?: string
  actionFunction?: () => void
}

interface ToastMessage extends ToastMessageWithoutId {
  id: number
}

const state = reactive({
  toastId: 1,
  toasts: [] as Array<ToastMessage>,
})

const addToast = (toast: ToastMessageWithoutId) => {
  state.toastId++

  toast.id = state.toastId

  if (toast.timeout === undefined || toast.timeout === null) {
    toast.timeout = 5000
  }

  state.toasts.push(toast as ToastMessage)
}

const removeToast = (toast: ToastMessage) => {
  const idx = state.toasts.indexOf(toast)
  // console.debug('idx', idx)
  if (idx !== -1) {
    state.toasts.splice(idx, 1)
  }
}

export function useToastMessages() {
  const toasts = computed(() => state.toasts)

  return {
    toasts,
    addToast,
    removeToast,
  }
}
