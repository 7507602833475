import Vue from 'vue'

import '@babel/polyfill'

import '@/assets/animations.css'
import '@/assets/theme.css'
import config from '@/config'
import { useAuth0 } from '@/infrastructure/auth'
import { initializeAppInsights } from '@/infrastructure/plugins/appInsights'
import '@/infrastructure/plugins/googleMaps'
import '@/infrastructure/plugins/vueCompositionApi'
import vuetify from '@/infrastructure/plugins/vuetify'
import '@/infrastructure/registerServiceWorker'
import router from '@/infrastructure/router/router'
import store from '@/infrastructure/store'

// eslint-disable-next-line import/order
import App from '@/App.vue' // must import after vueCompositionApi to ensure we can use composition in top-level components

console.debug({ config })

initializeAppInsights()

store.dispatch(
  'auth/initializeAuth',
  useAuth0({
    domain: config.Auth0.Domain,
    clientId: config.Auth0.ClientId,
    audience: config.Auth0.Audience,
    onRedirectCallback: (appState: { targetUrl?: string }) => {
      router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname)
    },
  }),
)

Vue.config.productionTip = false

const isPwaInstalled = !!(location.search && (location.search.includes('?pwa=1') || location.search.includes('&pwa=1')))
store.commit('setPwaIsInstalled', { isInstalled: isPwaInstalled })

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
