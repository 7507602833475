import Vue from 'vue'

import VueRouter from 'vue-router'

import config from '@/config'
import adminChildRoutes from '@/infrastructure/admin/adminChildRoutes'
import { RouteDefinition } from '@/infrastructure/router/routeDefinition'
import store from '@/infrastructure/store'
import { toTitleCase } from '@/infrastructure/strings/stringCasing'
import playgroundChildRoutes from '@/playground/playgroundChildRoutes'

import HomeView from '@/features/home/HomeView.vue'
import PaymentsPayfastRedirectView from '@/features/payments/views/PaymentsPayfastRedirectView.vue'

Vue.use(VueRouter)

const adminRole = config.AdminRoleName

export const routes: Array<RouteDefinition> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      icon: 'mdi-home',
      bottomNav: true,
    },
  },

  {
    path: '/patron/find-worker/:errandType',
    name: 'find-worker',
    component: () => import('@/features/errands/patron/views/FindHelpView.vue'),
    props: true,
    meta: {
      navHide: true,
    },
  },

  {
    path: '/patron/job/:errandJobId',
    name: 'patron-single-job',
    component: () => import('@/features/errands/patron/views/PatronJobView.vue'),
    props: true,
    meta: {
      navHide: true,
    },
    children: [
      {
        path: 'chat',
        name: 'patron-job-chat',
        component: () => import('@/features/errands/patron/views/PatronJobChatDialogView.vue'),
        props: true,
        meta: {
          title: 'Job chat',
        },
      },
    ],
  },

  {
    path: '/worker/job/:errandJobId',
    name: 'worker-single-job',
    component: () => import('@/features/errands/worker/views/WorkerJobView.vue'),
    props: true,
    meta: {
      navHide: true,
    },
    children: [
      {
        path: 'chat',
        name: 'worker-job-chat',
        component: () => import('@/features/errands/worker/views/WorkerJobChatDialogView.vue'),
        props: true,
        meta: {
          title: 'Job chat',
        },
      },
    ],
  },

  {
    path: '/worker/profile/:workerUserId',
    name: 'worker-profile',
    component: () => import('@/features/errands/patron/views/WorkerProfileView.vue'),
    props: true,
    meta: {
      navHide: true,
    },
  },

  {
    path: '/errands/my-jobs',
    name: 'errands/my-jobs',
    component: () => import('@/features/errands/calendar/views/MyJobsView.vue'),
    meta: {
      icon: 'mdi-hammer-wrench',
      title: 'My Jobs',
      bottomNav: true,
    },
  },

  {
    path: '/worker/my-business-card',
    name: 'worker-business-card',
    component: () => import('@/features/errands/worker/views/WorkerBusinessCardView.vue'),
    meta: {
      icon: 'mdi-card-account-details-outline',
      title: 'My card',
      requiresBusinessCard: true,
      bottomNav: true,
    },
  },

  {
    path: '/payments/payfast-redirect',
    name: 'payments-payfast-redirect',
    component: PaymentsPayfastRedirectView,
    meta: {
      navHide: true,
    },
  },

  {
    path: '/all-chats',
    name: 'all-chats',
    component: () => import('@/features/errands/chat/views/AllChatsView.vue'),
    meta: {
      icon: 'mdi-chat-processing-outline',
      title: 'Chats',
      bottomNav: true,
      showUnreadChatMessageCount: true,
    },
  },

  {
    path: '/notifications',
    component: () => import('@/infrastructure/router/views/RouterView.vue'),
    meta: {
      navHide: true,
      icon: 'mdi-bell-outline',
    },
    children: [
      {
        path: '',
        name: 'notifications',
        component: () => import('@/infrastructure/notifications/views/UnreadNotificationsView.vue'),
      },
      {
        path: 'read',
        name: 'read-notifications',
        component: () => import('@/infrastructure/notifications/views/ReadNotificationsView.vue'),
        meta: {
          title: 'Read notifications',
        },
      },
    ],
  },
  {
    path: '/user-push-notification/:notificationId',
    component: () => import('@/infrastructure/router/views/RouterView.vue'),
    meta: {
      navHide: true,
      icon: 'mdi-bell-outline',
    },
    children: [
      {
        path: '',
        name: 'user-push-notifications',
        component: () => import('@/infrastructure/notifications/views/UserPushNotificationView.vue'),
        props: true,
      },
    ],
  },

  {
    path: '/profile',
    component: () => import('@/infrastructure/router/views/RouterView.vue'),
    meta: {
      navGroup: 'user-avatar-dropdown',
      title: 'Profile',
    },
    children: [
      {
        path: '',
        name: 'profile',
        component: () => import('@/infrastructure/users/views/ProfileView.vue'),
      },
      {
        path: 'worker-active-skills',
        name: 'worker-active-skills',
        component: () => import('@/features/errands/worker/views/WorkerActiveSkillsView.vue'),
      },
      {
        path: 'available-work-area',
        name: 'available-work-area',
        component: () => import('@/features/errands/worker/views/WorkerAvailableJobAreaView.vue'),
      },
      {
        path: 'patron-address',
        name: 'patron-address',
        component: () => import('@/features/errands/patron/views/PatronAddressView.vue'),
      },
    ],
  },

  {
    path: '/preferences',
    name: 'preferences',
    component: () => import('@/infrastructure/users/views/PreferencesView.vue'),
    meta: {
      navGroup: 'user-avatar-dropdown',
    },
  },

  {
    path: '/divider-1',
    meta: {
      isDivider: true,
      navGroup: 'user-avatar-dropdown',
    },
  },

  {
    path: '/feedback',
    name: 'feedback',
    component: () => import('@/infrastructure/userFeedbacks/views/UserFeedbackForCreatorView.vue'),
    meta: {
      navGroup: 'user-avatar-dropdown',
      showRealtimeIndicator: true,
    },
  },

  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '@/features/about/views/AboutView.vue'),
    meta: {
      navGroup: 'user-avatar-dropdown',
      showRealtimeIndicator: true,
    },
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import(/* webpackChunkName: "about" */ '@/features/about/views/PrivacyPolicyView.vue'),
    meta: {
      navHide: true,
      allowAnonymous: true,
    },
  },
  {
    path: '/terms-of-service',
    name: 'terms-of-service',
    component: () => import(/* webpackChunkName: "about" */ '@/features/about/views/TermsOfServiceView.vue'),
    meta: {
      navHide: true,
      allowAnonymous: true,
    },
  },

  {
    path: '/admin',
    name: 'admin',
    component: () => import('@/infrastructure/admin/views/AdminView.vue'),
    meta: {
      requiresRole: adminRole,
      icon: 'mdi-account-hard-hat',
      pageLayoutProps: config.PageLayouts.wideFluid,
    },
    children: adminChildRoutes,
  },

  {
    path: '/playground',
    name: 'playground',
    component: () => import(/* webpackChunkName: "playground" */ '@/playground/views/PlaygroundView.vue'),
    meta: {
      requiresRole: adminRole,
      icon: 'mdi-flask-empty-outline',
    },
    children: playgroundChildRoutes,
  },

  {
    path: '*',
    component: () => import('@/infrastructure/errors/views/PageNotFoundView.vue'),
    meta: {
      navHide: true,
      title: 'Page not found',
      isPageNotFound: true,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.afterEach((to) => {
  const routeTitle = to.meta?.title ?? to.name
  const capitalizedTitle = routeTitle ? toTitleCase(routeTitle) : undefined
  store.commit('setPageTitle', capitalizedTitle)
})

export default router
