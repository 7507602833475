import { IEventTelemetry, IPageViewTelemetry } from '@microsoft/applicationinsights-common'

import { appInsights } from '../plugins/appInsights'

import store from '@/infrastructure/store'

class Service {
  trackException(err: unknown, options: unknown) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const typesafeError = err instanceof Error ? err : new Error(err)

    const exception = Object.assign({}, { error: typesafeError }, options)
    appInsights.trackException(exception)
    appInsights.flush(false)
  }

  trackPageView({ name, uri, refUri, pageType, properties, measurements }: IPageViewTelemetry) {
    const isLoggedIn = !!store.state.auth.authUserId
    appInsights.trackPageView({ name, uri, refUri, pageType, isLoggedIn, properties, measurements })
  }

  trackEvent({ name, properties, measurements }: IEventTelemetry) {
    appInsights.trackEvent({ name, properties, measurements })
  }

  flush() {
    appInsights.flush(false)
  }
}

export const TelemetryService = new Service()
