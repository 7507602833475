import { ref } from '@vue/composition-api'

import store from '@/infrastructure/store'

export default function useAsyncLoading<TArg1 = void, TArg2 = void, TResponse = void>(
  actionFactory: (arg1: TArg1, arg2: TArg2) => void | Promise<TResponse>,
  { initialIsBusyValue, doNotAutoAddError }: { initialIsBusyValue?: boolean; doNotAutoAddError?: boolean } = {},
) {
  // noinspection PointlessBooleanExpressionJS
  const isBusy = ref<boolean>(!!initialIsBusyValue)
  const error = ref(undefined as unknown)

  const execute = async (arg1: TArg1, arg2: TArg2) => {
    isBusy.value = true
    error.value = undefined

    try {
      return await actionFactory(arg1, arg2)
    } catch (e) {
      if (!doNotAutoAddError) {
        store.commit('addError', e)
      }
      error.value = e
    } finally {
      isBusy.value = false
    }
  }

  return {
    isBusy,
    error,

    execute,
  }
}
