import Vue from 'vue'

import Vuex, { Plugin } from 'vuex'

import config from '@/config'
import { adminModule as admin } from '@/infrastructure/admin/admin.storeModule'
import auth from '@/infrastructure/auth/auth.storeModule'
import { notificationsModule as notifications } from '@/infrastructure/notifications/notifications.storeModule'
import { RootState } from '@/infrastructure/store/RootState'

import { userFeedbacksModule as userFeedbacks } from '@/infrastructure/userFeedbacks/userFeedbacks.storeModule'
import { userProfileModule as userProfile } from '@/infrastructure/userProfile/userProfile.storeModule'

Vue.use(Vuex)

const stateKeyPwaIsInstalled = 'pwa-is-installed'

let errorId = 0

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const initialState: RootState = {
  pwaIsInstalled: sessionStorage.getItem(stateKeyPwaIsInstalled) === '1',

  userToImpersonate: null,

  errors: [],

  pageTitle: config.AppName,
}

// const plugins: Plugin<RootState>[] = config.IsProductionEnvironment ? [] : [createLogger()]
const plugins: Plugin<RootState>[] = config.IsProductionEnvironment ? [] : []

export default new Vuex.Store<RootState>({
  strict: true,

  plugins,

  modules: {
    auth,
    admin,
    userFeedbacks,
    notifications,
    userProfile,
  },

  state: () => initialState,

  getters: {},

  mutations: {
    setPwaIsInstalled(state, { isInstalled }) {
      if (isInstalled) {
        // only setting pwaIsInstalled in when true is good, otherwise a false will overwrite a true that got loaded from the session (sessionStorage)
        state.pwaIsInstalled = true
        sessionStorage.setItem(stateKeyPwaIsInstalled, '1')
      }

      // We cannot remove it because it will behave weird. If user launches from PWA, navigates to another page, and refresh the app, it will be gone
      // else {
      //   state.pwaIsInstalled = false
      //   sessionStorage.removeItem(stateKeyPwaIsInstalled)
      // }
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    addError(state, error: any) {
      console.error('addError', { error, errorType: typeof error, response: error.response })

      const errorLines = []

      if (typeof error === 'string') {
        errorLines.push(error)
      }

      if (error.response && error.response.data && typeof error.response.data === 'string') {
        errorLines.push(error.response.data)
      }

      if (error.response && error.response.data && error.response.data.errors) {
        try {
          if (Array.isArray(error.response.data.errors)) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            error.response.data.errors.forEach((err: any) => {
              const parts = []
              if (err.message) parts.push(err.message)
              // if (err.code) parts.push(`[${err.code}]`)
              if (parts.length > 0) {
                errorLines.push(parts.join(' '))
              }
            })
          } else if (Object.values(error.response.data.errors).length > 0) {
            Object.values(error.response.data.errors).forEach((errStr) => {
              errorLines.push(errStr)
            })
          }
        } catch {
          // ignore
        }
      }

      if (error.response && error.response.data && error.response.data.title) {
        errorLines.push(error.response.data.title)
      }

      if (error.message && errorLines.length === 0) errorLines.push(error.message)

      errorId++
      const combinedMessage = errorLines.join('\n')

      const duplicateFoundIndex = state.errors.findIndex((e) => e.message === combinedMessage)
      if (duplicateFoundIndex >= 0) {
        console.warn('Skipping to add duplicate error', { combinedMessage, duplicateFoundIndex, errors: state.errors })
        return
      }

      state.errors.push({ id: String(errorId), message: combinedMessage, fullError: JSON.stringify(error) })
    },

    removeError(state, error) {
      const idx = state.errors.indexOf(error)
      // console.debug('idx', idx)
      if (idx !== -1) {
        state.errors.splice(idx, 1)
      }
    },

    async setPageTitle(state, title) {
      const fullTitle = title ? `${title} | ${config.AppName}` : config.AppName

      state.pageTitle = fullTitle

      await Vue.nextTick()
      document.title = fullTitle
    },
  },

  actions: {},
})
