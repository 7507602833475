
import { computed, defineComponent, watch } from '@vue/composition-api'

import config from '@/config'
import * as realtime from '@/infrastructure/realtime'
import { PageLayoutProps, RouteDefinition } from '@/infrastructure/router/routeDefinition'
import store from '@/infrastructure/store'

import WelcomeLoginCard from '@/infrastructure/auth/views/WelcomeLoginCard.vue'
import GlobalErrorsContainer from '@/infrastructure/errors/components/GlobalErrorsContainer.vue'
import AppNav from '@/infrastructure/layout/AppNav.vue'
import OfflineWarningAlert from '@/infrastructure/pwa/OfflineWarningAlert.vue'
import UpdateManager from '@/infrastructure/pwa/UpdateManager.vue'
import GlobalToastsContainer from '@/infrastructure/toasts/components/GlobalToastsContainer.vue'

export default defineComponent({
  name: 'App',

  components: {
    AppNav,
    WelcomeLoginCard,
    GlobalErrorsContainer,
    GlobalToastsContainer,
    UpdateManager,
    OfflineWarningAlert,
  },

  setup() {
    const authUserId = computed(() => store.state.auth.authUserId)
    const hasOfficialUserId = computed(() => store.state.auth.hasOfficialUserId)

    watch(
      hasOfficialUserId,
      async () => {
        await realtime.stopRealtimeConnection()
        if (hasOfficialUserId.value) {
          // console.debug('User logged in with official id, starting realtime connection')
          await realtime.createRealtimeConnection()
        } else {
          // console.debug('User NOT logged in, stopped polling')
        }
      },
      { immediate: true },
    )

    return {
      authUserId,
    }
  },

  computed: {
    currentRoute(): RouteDefinition {
      return this.$route as RouteDefinition
    },

    pageLayoutProps(): PageLayoutProps {
      return this.currentRoute.meta?.pageLayoutProps || config.PageLayouts.default
    },

    allowAnonymous() {
      return this.$route && this.$route.meta && this.$route.meta.allowAnonymous
    },
    authIsAuthenticated() {
      return this.$store.state.auth.authIsAuthenticated
    },
    authUser() {
      return this.$store.state.auth.authUser
    },
    authBusyLoading() {
      return this.$store.state.auth.authBusyLoading
    },
    authHasError: () => store.getters['auth/authHasError'],

    unmetAuthRequirement() {
      if (this.authUser && !this.authUser.email_verified) return 'Your email is not verified yet, please verify it first'
      return undefined
    },

    realtimeIsConnected: () => realtime.state.isConnected,
    realtimeIsInStartupGracePeriod: () => realtime.state.isInStartupGracePeriod,
  },
})
