import { ApplicationInsights, DistributedTracingModes, ITelemetryItem } from '@microsoft/applicationinsights-web'

import config from '../../config'

import store from '@/infrastructure/store'

export const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: config.AppInsightsInstrumentationKey,
    enableAutoRouteTracking: true,
    distributedTracingMode: DistributedTracingModes.AI_AND_W3C,
    enableCorsCorrelation: true,
    correlationHeaderExcludedDomains: ['*.auth0.com'], // to avoid extra headers getting passed into '...auth0.com/oauth/token' call
    disableTelemetry: !config.AppInsightsEnabled,
    enableUnhandledPromiseRejectionTracking: true,
  },
})

export const initializeAppInsights = function () {
  appInsights.loadAppInsights()

  appInsights.addTelemetryInitializer(function (envelope: ITelemetryItem) {
    if (!envelope.tags) envelope.tags = []
    envelope.tags['ai.cloud.role'] = config.AppInsightsCloudRoleName
  })

  store.watch(
    (state) => state.auth.authUserId,
    (userId) => {
      if (userId) {
        appInsights.setAuthenticatedUserContext(userId, userId, true)
      } else {
        appInsights.clearAuthenticatedUserContext()
      }
    },
    { immediate: true },
  )
}
