import { createApiInstance } from '@/infrastructure/apis/apiInstance'

export interface RecordAppStartupResponse {
  userId: string
  hasBusinessCard: boolean
}

const userProfileApi = {
  recordUserAppStartup: async (): Promise<RecordAppStartupResponse> => {
    const response = await createApiInstance().post('/UserProfile/record-app-startup')
    return response.data as RecordAppStartupResponse
  },
}
export default userProfileApi
