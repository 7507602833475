export enum ErrandJobType {
  Research = 'Research',
  GraphicDesign = 'GraphicDesign',
  DigitalMarketing = 'DigitalMarketing',
  PetSitting = 'PetSitting',
  Domestic = 'Domestic',
  Gardening = 'Gardening',
  ErrandRunning = 'ErrandRunning',
  Nanny = 'Nanny',
}

export enum ErrandJobStatus {
  WaitingForQuote = 'WaitingForQuote',
  RejectedByWorker = 'RejectedByWorker',
  AcceptedByWorker = 'AcceptedByWorker',
  RejectedByPatron = 'RejectedByPatron',
  AcceptedByPatron = 'AcceptedByPatron',
  PaidByPatron = 'PaidByPatron',
}

export enum PriceUnit {
  PerHour = 'PerHour',
  PerDay = 'PerDay',
  PerItem = 'PerItem',
}

// export type ErrandTypeStrings = keyof typeof ErrandType

// console.debug(ErrandType[ErrandType.PetSitting]) // get string

export enum CalendarEntryType {
  ErrandJob = 'ErrandJob',
}

export const errandNames: Record<ErrandJobType, string> = {
  [ErrandJobType.Research]: 'Research',
  [ErrandJobType.GraphicDesign]: 'Graphic design',
  [ErrandJobType.DigitalMarketing]: 'Digital marketing',
  [ErrandJobType.PetSitting]: 'Pet / house sitting',
  [ErrandJobType.Domestic]: 'Domestic work',
  [ErrandJobType.Gardening]: 'Gardening',
  [ErrandJobType.ErrandRunning]: 'Errand running',
  [ErrandJobType.Nanny]: 'Nanny',
}

export const priceUnitDisplayNameAsRate: Record<PriceUnit, string> = {
  PerHour: 'per hour',
  PerDay: 'per day',
  PerItem: 'per item',
}

export const priceUnitDisplayNameAsValue: Record<PriceUnit, string> = {
  PerHour: 'hours',
  PerDay: 'days',
  PerItem: 'items',
}

export interface PriceWithUnit {
  amount: number
  unit: PriceUnit
}

export interface ErrandCalendarEntry {
  date: string
  entryType: CalendarEntryType
  jobSummary?: {
    errandJobId: string
    patronId: string
    workerId: string
    errandType: ErrandJobType

    description: string
    status: ErrandJobStatus
    startTime: string
    quantity: number
    priceAmount: number
    priceUnit: PriceUnit
    totalAmount: number
    otherUserId: string

    isJobPatron: boolean
    isJobWorker: boolean
  }
}

export interface ChatListSummaryResponse {
  errandJobId: string

  participantIsPatron: boolean
  jobType: ErrandJobType
  otherUserId: string
  startTime: string

  unreadMessageCount: number
  readMessageCount: number
  totalMessageCount: number
  newestMessageDate?: string
}

export interface JobChatMessageViewModel {
  messageId: string
  errandJobId: string
  createdOn: string
  createdOnUnixSeconds: number
  senderId: string
  messageText: string
  isRead: boolean
}

export interface JobChatMessagesResponse {
  messages: Array<JobChatMessageViewModel>

  oldestDate: string
  newestDate: string
}

export interface JobTimelineResponse {
  errandJobId: string
  createdOn: string
  startTime?: string
  quoteCreatedByWorkerOn?: string
  quoteAcceptedByPatronOn?: string
  paidByPatronOn?: string
}
