
import { defineComponent } from '@vue/composition-api'
import { PropType } from '@vue/composition-api'

import config from '@/config'
import { RouteDefinition } from '@/infrastructure/router/routeDefinition'

import NotificationsTopnavPopupPanel from '@/infrastructure/notifications/components/NotificationsTopnavPopupPanel.vue'

export default defineComponent({
  components: {
    NotificationsTopnavPopupPanel,
  },

  props: {
    isAuthenticated: { type: Boolean, required: true },
    authBusyLoading: { type: Boolean, required: true },
    authHasError: { type: Boolean, required: true },

    unreadNotificationCount: { type: Number, required: true },

    realtimeIsConnected: { type: Boolean, required: true },
    realtimeIsInStartupGracePeriod: { type: Boolean, required: true },

    routes: { type: Array as PropType<RouteDefinition[]>, required: true },
    profilePicUrl: { type: String, default: () => '' },
  },

  data() {
    return {
      logoUrl: config.LogoUrl,
    }
  },

  computed: {
    hasAnyNotifications(): boolean {
      return this.unreadNotificationCount > 0
    },
    shortenedUnreadNotificationCount(): string {
      return this.unreadNotificationCount > 99 ? '99+' : String(this.unreadNotificationCount)
    },
    showRealtimeDisconnectedIndicator(): boolean {
      return !this.realtimeIsConnected && !this.realtimeIsInStartupGracePeriod
    },
  },
})
