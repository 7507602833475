import { Module } from 'vuex'

import { createApiInstance } from '@/infrastructure/apis/apiInstance'

import { RootState } from '@/infrastructure/store/RootState'

export interface VuexUserFeedbackState {
  userFeedbacksForAdmin: []
  userFeedbacksForCreator: []
}

const initialState: VuexUserFeedbackState = {
  userFeedbacksForAdmin: [],
  userFeedbacksForCreator: [],
}

export const userFeedbacksModule: Module<VuexUserFeedbackState, RootState> = {
  namespaced: true,

  state: () => initialState,

  getters: {},

  mutations: {
    setUserFeedbacksForAdmin(state, { userFeedbacks }) {
      state.userFeedbacksForAdmin = userFeedbacks || []
    },
    setUserFeedbacksForCreator(state, { userFeedbacks }) {
      state.userFeedbacksForCreator = userFeedbacks || []
    },
  },

  actions: {
    async fetchUserFeedbacksForAdmin(context) {
      const response = await createApiInstance().get(`/UserFeedbackAdmin`)
      context.commit('setUserFeedbacksForAdmin', { userFeedbacks: response.data })
    },

    async submitNewUserFeedback(context, { message }) {
      await createApiInstance().post(`/UserFeedback`, { message: message })
      await context.dispatch('fetchUserFeedbacksForCreator')
    },
    async fetchUserFeedbacksForCreator(context) {
      const response = await createApiInstance().get(`/UserFeedback`)
      context.commit('setUserFeedbacksForCreator', { userFeedbacks: response.data })
    },
  },
}
