
import { defineComponent, PropType } from '@vue/composition-api'

import { useChatListTotalUnreadChatCountProvider } from '@/features/errands/chat/stores/chatsStore'
import { RouteDefinition } from '@/infrastructure/router/routeDefinition'

export default defineComponent({
  props: {
    routes: { type: Array as PropType<Array<RouteDefinition>>, required: true },
  },

  setup() {
    const { totalUnreadCount } = useChatListTotalUnreadChatCountProvider({ forceReload: true })

    return {
      totalUnreadCount,
    }
  },

  mounted() {
    if (this.routes.length === 0) {
      console.error('Bottomnav is used but number of routes are 0')
    }
  },
})
