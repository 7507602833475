
import Vue from 'vue'

import { errandNames } from '@/features/errands/errands-shared.models'
import { enabledErrands, enabledErrandShortlist, ErrandSummary } from '@/features/errands/patron/patron.models'

export default Vue.extend({
  data() {
    return {
      fullErrandShortlist: enabledErrands,
      subsetErrandShortlist: enabledErrandShortlist,
      showFullErrandShortlist: false,
    }
  },

  computed: {
    fullListHasMoreThanSubset(): boolean {
      return this.fullErrandShortlist.length > this.subsetErrandShortlist.length
    },

    shownErrands(): ErrandSummary[] {
      return (this.showFullErrandShortlist ? this.fullErrandShortlist : this.subsetErrandShortlist).map((t) => ({
        key: t,
        name: errandNames[t],
      }))
    },
  },

  methods: {
    onChooseErrandClick(errand: ErrandSummary) {
      this.$router.push({ name: 'find-worker', params: { errandType: errand.key } })
    },
  },
})
