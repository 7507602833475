
import { toRefs, PropType, defineComponent } from '@vue/composition-api'

import { reactiveFormatRelativeTimeAgo } from '@/infrastructure/dates/dateFormatting'
import { formatNotificationTemplateString } from '@/infrastructure/notifications/services/notificationFormatting'
import { CardAction, NotificationTypes } from '@/infrastructure/notifications/types'
import store from '@/infrastructure/store'

import NotificationActionButton from '@/infrastructure/notifications/components/NotificationActionButton.vue'

export default defineComponent({
  components: { NotificationActionButton },

  props: {
    notificationId: { type: String, required: true },
    type: { type: String as PropType<NotificationTypes>, required: true },
    typeId: { type: String, default: () => '' },
    // recipientDependentId: { type: String, default: () => '' },
    // recipientDependentName: { type: String, default: () => '' },
    defaultTitleTemplate: { type: String, required: true },
    defaultBodyTemplate: { type: String, default: () => '' },
    templateValues: { type: String, default: () => '' },
    extraData: { type: String, default: () => '' },
    created: { type: String, default: () => '' },
    readOn: { type: String, default: () => '' },
  },

  setup(props) {
    const createdRelativeTimeAgo = reactiveFormatRelativeTimeAgo(toRefs(props).created)

    return {
      createdRelativeTimeAgo,
    }
  },

  data() {
    return {
      formatNotificationTemplateString,

      isMarkingRead: false,
      imageWidth: 30,
      hadThumbnailLoadError: false,
    }
  },

  computed: {
    parsedExtraData(): Record<string, unknown> | undefined {
      try {
        return JSON.parse(this.extraData)
      } catch {
        return undefined
      }
    },

    errandJobIdFromExtraData(): string | undefined {
      return this.extractPropertyFromExtraData('ErrandJobId')
    },

    recipientIsJobWorkerFromExtraData(): boolean {
      return this.extractPropertyFromExtraData('RecipientIsJobWorker') === 'true'
    },

    thumbnailFullUrl(): string | undefined {
      // if (this.isFriendItemType) return `${config.ApiBaseUrl}/FriendItems/items/${this.typeId}/main-image`

      return undefined
    },

    avatarBadgeIcon(): string | undefined {
      if (this.type === NotificationTypes.RequestQuoteFromWorker) return `mdi-calendar-question`
      if (this.type === NotificationTypes.JobQuoteDeadlineDone) return `mdi-calendar-question`
      if (this.type === NotificationTypes.QuoteCreatedByWorker) return `mdi-calendar-check`
      if (this.type === NotificationTypes.JobRejectedByWorker) return `mdi-calendar-remove`
      if (this.type === NotificationTypes.JobAcceptedByPatron) return `mdi-calendar-check`
      if (this.type === NotificationTypes.JobRejectedByPatron) return `mdi-calendar-remove`
      if (this.type === NotificationTypes.JobPaymentConfirmed) return `mdi-cash-check`
      if (this.type === NotificationTypes.JobChatMessageSent) return `mdi-chat-outline`

      return undefined
    },

    actionToViewUserFeedbackPage() {
      return {
        name: 'View user feedback',
        attributes: { color: 'primary', outlined: true },
        routeTo: { name: 'feedback' },
      }
    },

    actionToViewAdminUserFeedbackPage() {
      return {
        name: 'View admin user feedback',
        attributes: { color: 'primary', outlined: true },
        routeTo: { name: 'admin-feedback' },
      }
    },

    actionToViewErrandJobAsWorker(): CardAction {
      return {
        name: 'View job',
        attributes: { color: 'primary', outlined: true },
        routeTo: { name: 'worker-single-job', params: { errandJobId: this.errandJobIdFromExtraData || '' } },
      }
    },

    actionToViewErrandJobAsPatron(): CardAction {
      return {
        name: 'View job',
        attributes: { color: 'primary', outlined: true },
        routeTo: { name: 'patron-single-job', params: { errandJobId: this.errandJobIdFromExtraData || '' } },
      }
    },

    actionToViewJobChatAsWorker(): CardAction {
      return {
        name: 'View job',
        attributes: { color: 'primary', outlined: true },
        routeTo: { name: 'worker-job-chat', params: { errandJobId: this.errandJobIdFromExtraData || '' } },
      }
    },

    actionToViewJobChatAsPatron(): CardAction {
      return {
        name: 'View job',
        attributes: { color: 'primary', outlined: true },
        routeTo: { name: 'patron-job-chat', params: { errandJobId: this.errandJobIdFromExtraData || '' } },
      }
    },

    mainCardAction(): CardAction | undefined {
      if (this.type === NotificationTypes.UserFeedbackReplyFromAdmin) return this.actionToViewUserFeedbackPage
      if (this.type === NotificationTypes.UserFeedbackAdded) return this.actionToViewAdminUserFeedbackPage

      if (this.type === NotificationTypes.RequestQuoteFromWorker) return this.actionToViewErrandJobAsWorker
      if (this.type === NotificationTypes.JobQuoteDeadlineDone) return this.actionToViewErrandJobAsPatron
      if (this.type === NotificationTypes.QuoteCreatedByWorker) return this.actionToViewErrandJobAsPatron
      if (this.type === NotificationTypes.JobAcceptedByPatron) return this.actionToViewErrandJobAsWorker
      if (this.type === NotificationTypes.JobPaymentConfirmed) return this.actionToViewErrandJobAsWorker

      if (this.type === NotificationTypes.JobChatMessageSent)
        return this.recipientIsJobWorkerFromExtraData ? this.actionToViewJobChatAsWorker : this.actionToViewJobChatAsPatron

      return undefined
    },

    actionButtons(): CardAction[] {
      // switch (this.type) {
      //   case NotificationTypes.NewFriendRequest:
      //     return this.actionsForNewFriendRequest
      // }

      return []
    },

    actions() {
      const actions: CardAction[] = []

      actions.push(...this.actionButtons)

      return actions
    },

    chips(): { text: string; icon: string; color?: string }[] {
      const chips: { text: string; icon: string; color?: string }[] = []

      // if (this.recipientDependentName) {
      //   chips.push({ text: this.recipientDependentName, icon: 'mdi-human-male-child' })
      // }

      return chips
    },

    cardListeners(): { click: () => void } | null {
      return this.mainCardAction ? { click: () => this.executeMainAction() } : null
    },
  },

  methods: {
    extractPropertyFromExtraData(propertyName: string): string | undefined {
      const parsedExtraData = this.parsedExtraData

      if (!parsedExtraData) {
        console.warn('parsedExtraData is undefined')
        return undefined
      }

      const propertyValue = parsedExtraData[propertyName]
      if (!propertyValue) {
        console.warn(`${propertyName} (of parsedExtraData) is undefined`)
        return undefined
      }

      return String(propertyValue)
    },

    executeMainAction() {
      if (!this.readOn) {
        this.markRead()
      }

      if (this.mainCardAction && this.mainCardAction.func) {
        return this.mainCardAction.func()
      } else if (this.mainCardAction && this.mainCardAction.routeTo) {
        this.$router.push(this.mainCardAction.routeTo)
      }
    },

    async markRead() {
      this.isMarkingRead = true
      try {
        await store.dispatch('notifications/markNotificationRead', { notificationId: this.notificationId })
      } finally {
        this.isMarkingRead = false
      }
    },
  },
})
