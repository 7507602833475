import { computed, onMounted, Ref, ref, watch } from '@vue/composition-api'

import { useMemoize } from '@vueuse/core'

import {
  callGetJobChatMessage,
  callGetJobChatMessageList,
  callGetJobChatUnreadCount,
  callMarkJobMessagesReadUntil,
  callSendJobChatMessage,
  getChatListSummaries,
  getChatListSummary,
  getTotalUnreadChatCount,
} from '@/features/errands/errands-shared.api'
import { ChatListSummaryResponse, JobChatMessagesResponse, JobChatMessageViewModel } from '@/features/errands/errands-shared.models'
import useAsyncLoading from '@/infrastructure/apis/asyncLoadingComposable'
import { subscribeToServerEvent } from '@/infrastructure/realtime'
import { useToastMessages } from '@/infrastructure/toasts/useToastMessages'
import debounce from '@/infrastructure/userInput/debounce'

export interface QueuedMessage {
  messageId: string
  messageText: string
}

const totalUnreadCount = ref(0)
const chatListSummaryRepository = ref<Record<string, ChatListSummaryResponse>>({})

const messagesRepository = ref<Record<string, JobChatMessageViewModel>>({})
const oldestMessageDatePerJob = ref<Record<string, Date>>({})
const unreadCountPerJob = ref<Record<string, number>>({})

const setSummary = (summary: ChatListSummaryResponse) => {
  chatListSummaryRepository.value = { ...chatListSummaryRepository.value, [summary.errandJobId]: summary }
}

const fetchTotalUnreadCount = useMemoize(async (): Promise<void> => {
  totalUnreadCount.value = await getTotalUnreadChatCount()
})

const fetchChatListSummaries = useMemoize(async (): Promise<void> => {
  const summaries = await getChatListSummaries()
  summaries.forEach((summary) => setSummary(summary))
})

const fetchChatListSummary = useMemoize(async (errandJobId: string): Promise<void> => {
  const summary = await getChatListSummary({ errandJobId: errandJobId })
  setSummary(summary)
})

const onRealtimeJobMessageCountsUpdated = async (errandJobId: string, messageIdsJsonArray: string) => {
  const messageIds = JSON.parse(messageIdsJsonArray) as Array<string>
  fetchChatListSummary.delete(errandJobId)
  fetchTotalUnreadCount.clear()
  const fetchMessagesPromises = messageIds.map((msgId) => fetchSingleMessage({ errandJobId, chatMessageId: msgId }))
  await Promise.all([fetchChatListSummary(errandJobId), fetchTotalUnreadCount(), fetchUnreadCount(errandJobId), ...fetchMessagesPromises])
}
subscribeToServerEvent('JobMessageCounts:Updated', onRealtimeJobMessageCountsUpdated)

const onRealtimeChatCreated = async (messageId: string, errandJobId: string) => {
  fetchChatListSummary.delete(errandJobId)
  fetchTotalUnreadCount.clear()
  fetchBatchOfMessages.clear()

  const message = await fetchSingleMessage({ errandJobId, chatMessageId: messageId })

  const batchSizeToFetchBeforeLatestMessage = 5 //TODO: assuming we would never miss more than 5 realtime messages while being offline

  await Promise.all([
    fetchChatListSummary(errandJobId),
    fetchTotalUnreadCount(),
    fetchBatchOfMessages(errandJobId, message.createdOn, batchSizeToFetchBeforeLatestMessage),
    fetchUnreadCount(errandJobId),
  ])
}
subscribeToServerEvent('JobChatMessage:Created', onRealtimeChatCreated)

const onRealtimeJobChatSummaryUpdated = debounce(async (errandJobId: string) => {
  fetchTotalUnreadCount.clear()

  await Promise.all([fetchTotalUnreadCount(), fetchUnreadCount(errandJobId)])
}, 2000)
subscribeToServerEvent('JobChatSummary:Updated', onRealtimeJobChatSummaryUpdated)

export function useChatListTotalUnreadChatCountProvider({ forceReload }: { forceReload?: boolean }) {
  if (forceReload) fetchTotalUnreadCount.clear()

  const { isBusy: isLoading, execute: fetchUnreadCount } = useAsyncLoading(fetchTotalUnreadCount)

  onMounted(async () => {
    await fetchUnreadCount()
  })

  return {
    totalUnreadCount,
    isLoading,
  }
}

export function useChatListSummariesProvider({ forceReload }: { forceReload?: boolean }) {
  if (forceReload) fetchChatListSummaries.clear()

  const { isBusy: isLoading, execute: fetchAll } = useAsyncLoading(fetchChatListSummaries)

  onMounted(async () => {
    await fetchAll()
  })

  return {
    summaries: chatListSummaryRepository,
    isLoading,
  }
}

const getEarliestMessageTime = (errandJobId: string): string | undefined => {
  return oldestMessageDatePerJob.value[errandJobId]?.toISOString() ?? undefined
}

const setMessage = (message: JobChatMessageViewModel) => {
  messagesRepository.value = { ...messagesRepository.value, [message.messageId]: message }
}

const setMessagesFromResponse = (errandJobId: string, messagesResponse: JobChatMessagesResponse) => {
  if (messagesResponse.messages.length > 0) {
    messagesResponse.messages.forEach((msg) => setMessage(msg))

    const responseOldestDate = new Date(messagesResponse.oldestDate)
    if (!oldestMessageDatePerJob.value[errandJobId]) {
      oldestMessageDatePerJob.value[errandJobId] = responseOldestDate
    } else if (responseOldestDate < oldestMessageDatePerJob.value[errandJobId]) {
      oldestMessageDatePerJob.value[errandJobId] = responseOldestDate
    }
  }
}

const fetchUnreadCount = async (errandJobId: string) => {
  const unreadCount = await callGetJobChatUnreadCount({ errandJobId: errandJobId })
  unreadCountPerJob.value = { ...unreadCountPerJob.value, [errandJobId]: unreadCount }
}

const fetchBatchOfMessages = useMemoize(async (errandJobId: string, beforeTime: string | undefined, limit: number | undefined = 10) => {
  const requestPayload = {
    errandJobId: errandJobId,
    beforeTime: beforeTime,
    limit: limit,
  }
  const messagesResponse = await callGetJobChatMessageList(requestPayload)

  setMessagesFromResponse(errandJobId, messagesResponse)

  await fetchUnreadCount(errandJobId)
})

const fetchSingleMessage = async ({ errandJobId, chatMessageId }: { errandJobId: string; chatMessageId: string }) => {
  const message = await callGetJobChatMessage({ errandJobId: errandJobId, chatMessageId: chatMessageId })
  setMessage(message)
  return message
}

const markMessagesReadUntil = async ({ errandJobId, untilDate }: { errandJobId: string; untilDate: string }) => {
  await callMarkJobMessagesReadUntil({ errandJobId, untilDate })
  await fetchUnreadCount(errandJobId)
}

export function useJobChatsUnreadCountProvider({ errandJobId, forceReload }: { errandJobId: Ref<string>; forceReload?: boolean }) {
  const unreadCount = computed(() => unreadCountPerJob.value[errandJobId.value] || 0)

  watch(
    errandJobId,
    async () => {
      if (forceReload || unreadCountPerJob.value[errandJobId.value] == null || unreadCountPerJob.value[errandJobId.value] == undefined) {
        await fetchUnreadCount(errandJobId.value)
      }
    },
    { immediate: true },
  )

  return {
    unreadCount: unreadCount,
  }
}

export function useJobChatsProvider({ errandJobId, forceReload }: { errandJobId: string; forceReload?: boolean }) {
  if (forceReload) fetchBatchOfMessages.clear()

  const { isBusy: isLoading, execute: fetchPreviousBatch } = useAsyncLoading(() => fetchBatchOfMessages(errandJobId, getEarliestMessageTime(errandJobId)))

  const messagesOfJob = computed(() =>
    Object.values(messagesRepository.value)
      .filter((m) => m.errandJobId === errandJobId)
      .sort((a, b) => (a.createdOn > b.createdOn ? 1 : b.createdOn > a.createdOn ? -1 : 0)),
  )

  onMounted(async () => {
    if (forceReload || !messagesOfJob.value || messagesOfJob.value.length === 0) {
      delete oldestMessageDatePerJob.value[errandJobId]
      await fetchPreviousBatch()
    }
  })

  return {
    messages: messagesOfJob,
    isLoading,

    fetchPreviousBatch,
    markMessagesReadUntil: ({ untilDate }: { untilDate: string }) => markMessagesReadUntil({ errandJobId, untilDate }),
  }
}

export function useJobChatsSender() {
  return {
    addOrReplaceMessage: (message: JobChatMessageViewModel) => setMessage(message),
  }
}

export function useQueuedMessageSender({ errandJobId }: { errandJobId: string }) {
  const { addOrReplaceMessage } = useJobChatsSender()
  const { addToast } = useToastMessages()

  const id = ref(new Date().getTime())
  const messageQueue = ref<Array<QueuedMessage>>([])

  const consecutiveFailureCount = ref(0)
  const maxAllowedConsecutiveFailures = 10

  const isBusyAdding = ref(false)
  const sendNextMessage = async () => {
    if (messageQueue.value.length === 0) return

    if (isBusyAdding.value) return

    try {
      isBusyAdding.value = true

      const firstMessage = messageQueue.value[0]
      const addedMessage = await callSendJobChatMessage({
        errandJobId: errandJobId,
        messageText: firstMessage.messageText,
      })
      addOrReplaceMessage(addedMessage)

      messageQueue.value.shift()
      consecutiveFailureCount.value = 0
    } catch (err) {
      consecutiveFailureCount.value++
      const retryDelaySeconds = 3

      if (consecutiveFailureCount.value < maxAllowedConsecutiveFailures) {
        console.error(`Something went wrong wile trying to sendNextMessage for chat, retrying in ${retryDelaySeconds} seconds`, err)
        setTimeout(() => {
          sendNextMessage()
        }, retryDelaySeconds * 1000)
      } else {
        console.error(
          `Something went wrong wile trying to sendNextMessage for chat, maximum consecutive failures reached (${maxAllowedConsecutiveFailures})`,
          err,
        )
        addToast({
          message: 'Too many failures occurred trying to send your chat messages, please reload the page and try again',
          color: 'error',
          timeout: 15000,
        })
      }
    } finally {
      isBusyAdding.value = false

      if (messageQueue.value.length > 0) {
        // noinspection ES6MissingAwait
        sendNextMessage()
      }
    }
  }

  const enqueueMessage = ({ messageText }: { messageText: string }) => {
    messageQueue.value.push({
      messageId: String(id.value++),
      messageText,
    })
    // noinspection JSIgnoredPromiseFromCall
    sendNextMessage()
  }

  return {
    messageQueue,
    enqueueMessage,
  }
}
