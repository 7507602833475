
import { defineComponent } from '@vue/composition-api'

import config from '@/config'
import useRealtimeSubscriptions from '@/infrastructure/realtime/realtimeSubscriptions'
import { routes } from '@/infrastructure/router/router'
import { filterVisibleRoutes } from '@/infrastructure/router/services/routerHelperService'
import store from '@/infrastructure/store'

import BottomNav from '@/infrastructure/router/components/BottomNav.vue'
import SideNav from '@/infrastructure/router/components/SideNav.vue'
import TopNav from '@/infrastructure/router/components/TopNav.vue'

export default defineComponent({
  components: {
    TopNav,
    BottomNav,
    SideNav,
  },

  props: {
    isAuthenticated: { type: Boolean, required: true },
    authBusyLoading: { type: Boolean, required: true },
    authHasError: { type: Boolean, required: true },

    authUserId: { type: String, default: () => '' },
    authUser: { type: Object, default: () => null },

    realtimeIsConnected: { type: Boolean, required: true },
    realtimeIsInStartupGracePeriod: { type: Boolean, required: true },
  },

  setup() {
    const { subscribeToRealtimeEvent } = useRealtimeSubscriptions()
    subscribeToRealtimeEvent('Notification:Created', () => store.dispatch('notifications/fetchUnreadNotificationCount'))
    subscribeToRealtimeEvent('Notifications:MarkedRead', () => store.dispatch('notifications/fetchUnreadNotificationCount'))
  },

  data() {
    return {
      appName: config.AppName,
      showSidenav: null,
    }
  },

  computed: {
    sidenavRoutes() {
      const userRoles = this.userRoles as string[]
      const isImpersonating = this.isImpersonating as boolean
      const hasBusinessCard = this.hasBusinessCard as boolean
      return filterVisibleRoutes({
        routes,
        authUserRoles: userRoles,
        authUserIsImpersonating: isImpersonating,
        authUserHasBusinessCard: hasBusinessCard,
        navGroup: undefined,
      })
    },
    userAvatarDropdownRoutes() {
      const userRoles = this.userRoles as string[]
      const isImpersonating = this.isImpersonating as boolean
      const hasBusinessCard = this.hasBusinessCard as boolean
      return filterVisibleRoutes({
        routes,
        authUserRoles: userRoles,
        authUserIsImpersonating: isImpersonating,
        authUserHasBusinessCard: hasBusinessCard,
        navGroup: 'user-avatar-dropdown',
      })
    },
    bottomNavRoutes() {
      const userRoles = this.userRoles as string[]
      const isImpersonating = this.isImpersonating as boolean
      const hasBusinessCard = this.hasBusinessCard as boolean
      return filterVisibleRoutes({
        routes,
        authUserRoles: userRoles,
        authUserIsImpersonating: isImpersonating,
        authUserHasBusinessCard: hasBusinessCard,
        bottomNav: true,
      })
    },
    userRoles(): string[] {
      return this.$store.state.auth.authUserRoles
    },
    isImpersonating() {
      return !!store.state.userToImpersonate
    },
    hasBusinessCard(): boolean {
      return !!this.$store.state.auth.hasBusinessCard
    },
    unreadNotificationCount(): number {
      return this.$store.state.notifications.unreadNotificationCount
    },
  },

  watch: {
    authUserId: {
      handler(to) {
        if (to) {
          store.dispatch('notifications/fetchUnreadNotificationCount')
        }
      },
      immediate: true,
    },
  },

  methods: {
    onLogoutClick() {
      store.commit('auth/authLogout')
    },
  },
})
